import React from 'react';
import { Icon } from 'figicons';
import { Stack, HStack, VStack } from "@chakra-ui/react"

export default function SocialIcons() {
    return (

        <div className="socialicons">
            <HStack spacing={8}>
            <a href="https://twitter.com/ashbakernz" target="_blank" rel="noreferrer noopener">
                <Icon name="twitter" fill="currentColor" strokeWidth={0} />
            </a>
            <a href="https://github.com/ashbakernz" target="_blank" rel="noreferrer noopener">
                <Icon name="github" fill="currentColor" strokeWidth={0} />
            </a>
            <a
                href="https://www.linkedin.com/in/ash-baker-19849813a"
                target="_blank"
                rel="noreferrer noopener"
            >
                <Icon name="linkedin" fill="currentColor" strokeWidth={0} />
            </a>
            </HStack>
        </div>
    );
}
