import React from "react";
import Avatar from "../images/avatar.jpg";
import About1 from "../images/about-1.png";
import About2 from "../images/about-2.png";
import About3 from "../images/about-3.png";
import About4 from "../images/about-4.png";
import { Image } from "@chakra-ui/react"
import { Text } from "@chakra-ui/react"
import Mention from "../components/Mention";
import SectionHeader from "../components/SectionHeader";

export default function About() {
    return (
        <main>
            <section className="about">
                <div className="container justify-between">

                    <div className="short fadeInDown">
                        <SectionHeader title="Hey, I'm Ash Baker." />

                        <Image
                            className="mobileavatar"
                            boxSize="200px"
                            src="{Avatar}"
                            alt="Avatar" />
                        <Text fontSize="2xl">
                            I'm a product manager with a keen
                            passion for design &amp; development, now working
                            remotely for one of the biggest photo printing applications out there.
                        </Text>
                        <Text fontSize="2xl">
                            Over the years my main focus has been on web engineering which evenultally lead me to become a product manager
                            for multi billion dollar company and engineer some of the worlds finest solutions and designs.
                        </Text>
                    </div>

                    <img className="fadeInUp" src={Avatar} alt="Avatar" />
                </div>

                <div className="container flex-column mt6 fadeInDown">
                    <Text fontSize="2xl">
                        Apart from tinkering with my own projects daily and
                        keeping up with the latest design &amp; dev trends,
                        I usually spend my free time driving to explore new
                        places and travelling to countries I've never been to
                        before. I'm a dad of two so most of my actual spare time
                        goes into being the best role model I could be.
                    </Text>
                </div>

                <div className="container flex-column mt12 fadeInUp">
                    <div className="images">
                        <img src={About1} alt="About 1" />
                        <img src={About2} alt="About 2" />
                        <img src={About3} alt="About 3" />
                        <img src={About4} alt="About 4" />
                    </div>
                </div>
            </section>

            {/* <section className="container flex-column fadeInDown">
                <SectionHeader
                    title="Mentions"
                    description="I was lucky enough for my work to be seen &amp; recognized by some awesome
                    people around the web."
                    descriptionStyle={{ maxWidth: 600 }}
                />

                <Mention
                    platform="Product Hunt"
                    year="2020"
                    product="Autopilot Print"
                    url="https://www.producthunt.com/posts/framer-for-web"
                />

                <Mention
                    platform="Product Hunt"
                    year="2018"
                    product="Overflow"
                    url="https://www.producthunt.com/posts/overflow"
                />
                <Mention
                    platform="Product Hunt"
                    year="2017"
                    product="The Colorbook"
                    url="https://www.producthunt.com/posts/the-colorbook"
                />
                <Mention
                    platform="InVision Blog"
                    year="2017"
                    product="The Colorbook"
                    url="https://www.invisionapp.com/inside-design/color-palettes"
                />
            </section> */}

        </main>
    );
}
