import React from "react";
import classNames from "classnames";
import { Icon } from "figicons";
import { Box, Badge, Text, ListItem, UnorderedList } from "@chakra-ui/react"
interface IProps {
    mode: "light" | "dark";
    title: string;
    description: string;
    url?: string;
}

export default function Experience({
    mode,
    title,
    description,
    url,
}: IProps) {

    return (
        // <div>
        //     <div className="info">
        //         <h4>{title}</h4>
        //         <p className="mt5">{description}</p>
        //         {url && (
        //             <a
        //                 href={url}
        //                 target="_blank"
        //                 rel="noopener noreferrer"
        //                 className={classNames(
        //                     "button mt8",
        //                     mode,
        //                 )}
        //             >
        //                 <span className="mr3">
        //                     Explore company
        //                 </span>{" "}
        //                 <Icon name="arrow-right" strokeWidth={3} />
        //             </a>
        //         )}
        //     </div>
        // </div>
        <Box maxW="4xl" borderWidth="1px" borderRadius="lg" overflow="hidden">
            <Box p="6">
                <Box d="flex" alignItems="baseline">
                    <Badge borderRadius="full" px="2" colorScheme="gray">
                        2019 - Present
                    </Badge>
                    <Box
                    color="gray.500"
                    fontWeight="semibold"
                    letterSpacing="wide"
                    fontSize="xs"
                    textTransform="uppercase"
                    ml="2"
                    >
                        MEA Mobile
                    </Box>
                </Box>

                <Box
                    mt="1"
                    fontWeight="semibold"
                    as="h4"
                    lineHeight="tight"
                    isTruncated
                >
                    Product Manager / Senior Developer
                </Box>

                <Box d="flex" mt="2" alignItems="center">

                    <Box as="span" ml="2" color="gray.600" fontSize="sm">
                        At MEA I mainly worked on two large projects. One project was an agricultural data processor which allowed farmers to analysis lab sample data into a machine learning algorithm that generated response's and recommendations for their farms. The other application is a photo printing platform which connects users to printing retailers which makes use of all printing spec requirements and multiple retailer API's. I was also the main contact/developer for doing all of our general web client tasks for our smaller websites (Mainly silverstripe 3 & 4, although some legacy PHP applications). My goal at MEA was to implement good use of design patterns within our applications so we would have good reusable code that can scale.
                    </Box>
                </Box>
                <Text
                    mt="1"
                    fontSize="2xl"
                    as="h4"
                >
                    Key Responsibilities
                </Text>
                <Box d="flex" mt="2" alignItems="center">
                    <Box as="span" ml="2" color="gray.600" fontSize="sm">
                        <UnorderedList>
                            <ListItem>Lorem ipsum dolor sit amet</ListItem>
                            <ListItem>Consectetur adipiscing elit</ListItem>
                            <ListItem>Integer molestie lorem at massa</ListItem>
                            <ListItem>Facilisis in pretium nisl aliquet</ListItem>
                        </UnorderedList>
                    </Box>
                </Box>
            </Box>
        </Box>

    );
}
