import React from 'react'
import {
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
  } from "@chakra-ui/react"

export default function NotFound() {
    return (
        <div className="container tc">
            <Alert status="error" mt="10">
                <AlertIcon />
                <AlertDescription>The page you've requested does not exist.</AlertDescription>
            </Alert>
        </div>
    );
}
