import React from "react";
import Project from "./Project";
import Splitter from "./Splitter";
import ImageSatcom from "../images/project-satcom.png";
import ImagePioneerEcology from "../images/project-pioneerecology.png";
import ImageAutopilot from "../images/project-autopilot.png";
import ImageQquicker from "../images/project-qquicker-2.png";
import ImageMindfulFeedback from "../images/project-mindful-feedback.png";

export default function Projects() {
    return (
        <section className="container flex-column fadeInUp">
            <Splitter title="Professional work" className="mb6" />
            
            <Project
                title="Mindful Feedback"
                description="A survey platform that allows users to create surveys, collect responses, and analyze results."
                mode="light"
                project="mindful-feedback"
                image={ImageMindfulFeedback}
                url="https://getmindful.com/platform/feedback/"
                product={true}
            />

            <Project
                title="Autopilot Print"
                description="An easy, convenient photo print solution that allows users to print to their nearest stores."
                mode="light"
                project="autopilot"
                image={ImageAutopilot}
                url="https://autopilotprint.com"
                product={true}
            />

            <Project
                title="Pioneer Ecology"
                description="AI powered plant monitoring and management system for the sustainable agriculture industry."
                mode="light"
                project="pioneer-ecology"
                isPrivate={true}
                image={ImagePioneerEcology}
                url="https://autopilotprint.com"
                product={true}
            />

            <Splitter title="Self&mdash;made work" className="mv6 pt12" />

            <Project
                title="QQuicker"
                description="Qquicker is an ordering platform designed by baristas, to provide customers with a way to pre-order and pay for their takeaway products while on the go."
                mode="light"
                project="qquicker"
                image={ImageQquicker}
                url="https://qquicker.com"
                product={true}
            />

            <Project
                title="Satcom"
                description="Beautiful, customizable invoicing software, send invoices, view payouts, and track business expenses."
                mode="light"
                project="satcom"
                image={ImageSatcom}
                isPrivate={true}
                url="https://letsbudget.app"
            />

        </section>
    );
}
