import React from "react";
import Experiences from "../components/Experiences";
import { Text } from "@chakra-ui/react"
import Splitter from "../components/Splitter";
import SectionHeader from "../components/SectionHeader";

export default function About() {
    return (
        <main>
            <section className="resume">


                <div className="container flex-column mt6 fadeInDown">
                    {/* Summary */}

                    I have proven to both myself and my employers that I can maintain and uphold a professional and satisfactory company image. I have an extensive background in technology and design, and experience applying various forms of knowledge into practice. I have the ability to learn fast along with standing out and impressing my employers and clients.




                    <Experiences />

                </div>
            </section>

        </main>
    );
}
