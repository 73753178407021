import React from "react";
import Projects from "../components/Projects";
import OpenSource from "../components/OpenSource";
import SectionHeader from "../components/SectionHeader";

export default function Home() {
    return (
        <main>
            <div className="container intro fadeInDown">
                <SectionHeader
                    title="Technical lead, engineer, ethical hacker, and coffee addict."
                    description=" With a rapidly growing industry, my target is to handcraft effective &amp; secure platforms for the present-day user."
                    titleStyle={{ maxWidth: 960 }}
                    descriptionStyle={{ maxWidth: 920 }}
                />
            </div>
            <Projects />
            <OpenSource />
        </main>
    );
}
