import React from "react";
import Splitter from "./Splitter";
import OpenSourceProject from "./OpenSourceProject";
import SectionHeader from "./SectionHeader";

export default function OpenSource() {
  return (
    <section className="opensource container flex-column fadeInDown">
      <SectionHeader
        title="Open Source"
        titleIcon="heart"
        description="I support open source so I try to give back to the community in any way I can.
                    Here are a couple of things I've built and released."
        descriptionStyle={{ maxWidth: 820 }}
      />

      <div className="flex flex-column mt6">
        <Splitter title="Laravel" className="mt12" />

        <OpenSourceProject
          title="Laravel Dashboard Spotify Tile"
          description="A realtime now playing tile for spaties laravel dashboard that's built using livewire."
          url="https://github.com/ashbakernz/laravel-dashboard-spotify-tile"
        />

        <Splitter title="Others" className="mt12" />
        <OpenSourceProject
          title="Place Coffee "
          description="  image placeholder app built with Silex micro framework that let's you place good looking caffeine all over your site."
          url="https://github.com/ashbakernz/place-coffee"
        />

      </div>
    </section>
  );
}
