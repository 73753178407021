import React from "react";
import Experience from "./Experience";
import Splitter from "./Splitter";

export default function Experiences() {
    return (
        <section className="container flex-column fadeInUp">
            <Splitter title="Experience" className="mb6" />

            <Experience title="MEA Mobile"
                description="At MEA I mainly worked on two large projects. One project was an agricultural data processor which allowed farmers to analyze lab sample data into a machine learning algorithm that generated response's and recommendations for their farms. The other application is a photo printing platform which connects users to printing retailers which makes use of all printing spec requirements and multiple retailer API's. I was also the main contact/developer for doing all of our general web client tasks for our smaller websites (Mainly silverstripe 3 & 4, although some legacy PHP applications). My goal at MEA was to implement good use of design patterns within our applications so we would have good reusable code that can scale."
                mode="light"
                url="https://we-are-mea.com" />
        </section>
    );
}
